<template>
  <div>
    <div class="page-title pageheading" style="height: 50px">
      <h3 class="titlename">
        <i class="fas fa-user"></i><span class="ml-3">Email Template</span>
      </h3>
      <span class="float-right green-btn">
        <a class="btn btn-success mr-3" @click="create"
          ><span><i class="material-icons">add</i> New Email Template</span></a
        >
      </span>
    </div>
    <div>
      <v-row>
        <v-col class="d-flex" cols="12" sm="3">
          <v-text-field
            v-model="search"
            label="Search Email"
            counter
            filled
            rounded
            clearable
            dense
            maxlength="50"
          >
            <template #label>
              <i class="fas fa-search"></i> Search Email
            </template>
          </v-text-field>
        </v-col>
        <v-col class="d-flex mt-4" cols="12" sm="6">
          <v-btn @click="Search"> Search </v-btn></v-col
        >
      </v-row>
    </div>
    <div
      v-if="!isfetching"
      class="container mt-10"
      style="background-color: #ffffff"
    >
      <b-table
        class="table table-bordered"
        show-empty
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="0"
        select-mode="single"
        selectable
        @row-selected="onRowSelected"
        striped
        hover
      >
        <template #cell(actions)="row">
          <button @click="Edit(row.item)" class="mr-1">
            <i class="far fa-eye edit_btn"></i>
          </button>
          <button
            v-b-modal.confirmModal
            @click="sendInfo(row.item.id)"
            class="mr-1"
          >
            <i class="fas fa-trash-alt del_btn"></i>
          </button>
          <!-- <b-button variant="primary">Edit</b-button>
          <b-button variant="danger">Delete</b-button> -->
        </template>
        <template #cell(name)="row">
          {{ row.item.name }}
        </template>
        <template #cell(emailText)="row">
          {{ setDots(row.item.emailText) }}
        </template>
        <template #cell(emailSubject)="row">
          {{ row.item.emailSubject }}
        </template>
      </b-table>
      <div class="row">
        <div class="col-6">
          <b-pagination
            size="md"
            :total-rows="totalItems"
            v-if="perPage != '-1'"
            v-model="currentPage"
            :per-page="perPage"
          ></b-pagination>
        </div>
        <div class="col-6 text-right">
          <span style="margin-right: 10px"><label for="">Show : </label></span>
          <select
            v-model="perPage"
            class="form-select"
            style="border: groove; width: 6%"
            aria-label="Default select example"
            @change="Search()"
          >
            <option selected>50</option>
            <option value="100">100</option>
            <option value="200">200</option>
            <option value="-1">All</option>
          </select>

          <span style="margin-left: 10px; margin-right: 20px">
            <label for=""> Entries </label></span
          ><span>TOTAL RECORDS: {{ this.totalItems }}</span>
        </div>
      </div>
    </div>
    <div
      v-else
      class="container mt-10"
      style="background-color: #ffffff; text-align: center"
    >
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <b-modal size="xl" id="createemail" centered no-close-on-backdrop>
      <template slot="modal-title">Email Template</template>
      <v-container data-app fluid>
        <div v-if="this.id" class="page-title pageheading" style="height: 50px">
          <span class="float-right green-btn">
            <a
              v-if="isEditAble"
              class="btn btn-success mr-3"
              @click="editClientinfo"
            >
              <i class="far fa-eye edit_btn"></i><span>Edit</span></a
            >
            <a v-else class="btn btn-success mr-3">
              <i class="far fa-eye edit_btn"></i
              ><span
                >Update
                <span v-if="isSending">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular> </span></span
            ></a>
          </span>
        </div>
        <v-row align="center">
          <div
            class="form-group col-md-6 mb-form"
            :class="{ invalid: $v.Name.$error }"
          >
            <v-text-field
              v-model.trim="$v.Name.$model"
              :disabled="isEditAble"
              label="Name "
              maxlength="20"
            ></v-text-field>
            <div class="invalid-feedback" v-if="$v.Name.$error">
              <span v-if="$v.Name.$error">Please enter Template name.</span>
            </div>
          </div>

          <div
            class="form-group col-md-6 mb-form"
            :class="{ invalid: $v.EmailSubject.$error }"
          >
            <v-text-field
              v-model.trim="$v.EmailSubject.$model"
              :disabled="isEditAble"
              label="Email Subject"
              maxlength="20"
            ></v-text-field>
            <div class="invalid-feedback" v-if="$v.EmailSubject.$error">
              <span v-if="$v.EmailSubject.$error"
                >Please enter Email Subject.</span
              >
            </div>
          </div>

          <div class="row">
            <div class="col-sm-10">
              <!-- firstName -->
                <h4>Email Text:</h4>
                <div id="size-fixed">
                <vue-editor class="mt-3" v-model.trim="$v.EmailText.$model">
                </vue-editor>
              </div>
            </div>
          </div>
        </v-row>
      </v-container>
      <template #modal-footer="{ cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button v-if="id" size="lg" variant="success" @click="ok()">
          Update
          <span v-if="isSending">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </span>
        </b-button>
        <b-button v-else size="lg" variant="success" @click="ok()">
          Create
          <span v-if="isSending">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </span>
        </b-button>
        <b-button size="lg" variant="danger" @click="cancel()">
          Cancel
        </b-button>
        <!-- Button with custom close trigger value -->
      </template>
    </b-modal>

    <b-modal
      centered
      text
      id="confirmModal"
      title="Are you sure you want to Delete?"
      hide-footer
    >
      <div class="text-center">
        <p>Are you sure you want to delete?</p>
        <span class="green-btn">
          <a class="btn btn-success mr-3" @click="remove(emailId)"
            ><span
              >YES I'M SURE
              <span v-if="isRemoving">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular> </span></span
          ></a>
        </span>
        <span class="green-btn">
          <a class="btn btn-danger mr-3" @click="$bvModal.hide('confirmModal')"
            ><span>Cancel</span></a
          >
        </span>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import { VueEditor } from "vue2-editor";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  components: {
    VueEditor,
  },
  mixins: [validationMixin],
  validations: {
    Name: {
      required,
      minLength: minLength(1),
    },
    EmailSubject: {
      required,
      minLength: minLength(1),
    },
    EmailText: {
      minLength: minLength(0),
    },
  },
  data() {
    return {
      isEditAble: true,
      isSending: false,

      //create
      EmailText: "",
      EmailSubject: "",
      Name: "",
      id: "",

      EmailTemplate1: {
        Name: "",
        EmailText: "",
        EmailSubject: "",
      },
      isfetching: false,
      isRemoving: false,
      emailId: "",
      action: "",
      items: [],
      fields: [
        {
          key: "name",
          label: "Template name",
        },
        {
          key: "emailText",
          label: "Email Text",
        },
        {
          key: "emailSubject",
          label: "Email Subject",
        },
        {
          key: "actions",
          label: "Action",
        },
      ],
      currentPage: 1,
      perPage: 50,
      totalItems: 10,
      search: "",
      desserts: [],
    };
  },
  computed: {
    ...mapGetters(["Email", "totalEmailRow"]),
  },
  watch: {
    currentPage: {
      handler: function () {
        this.fetchData().catch((error) => {
          console.error(error);
        });
      },
    },
    totalEmailRow: {
      handler: function () {
        this.items = this.Email;
      },
    },
  },
  mounted() {
    this.fetchData().catch((error) => {
      //console.error(error);
    });
  },
  methods: {
    editClientinfo() {
      this.isEditAble = false;
    },
    removeTag(obj) {
      var tmp = document.createElement("DIV");
      tmp.innerHTML = obj;
      return tmp.textContent || tmp.innerText || "";
    },
    setDots(line){
    var dots = '....';
    var tmp = document.createElement("DIV");
    tmp.innerHTML = line;
    tmp.textContent || tmp.innerText || "";
     if(tmp.textContent.length > 50){
        var str = tmp.textContent.slice(0, 80);
        str = str.concat(dots);
        return str;
     }
     else{ return tmp.textContent; }
    },
    async fetchData() {
      this.isfetching = true;
      this.search = this.search == null ? "" : this.search;
      await this.$store
        .dispatch("getEmail", {
          pageNo: this.currentPage,
          pageSize: this.perPage,
          filter: this.search,
        })
        .then((response) => {
          if (response.message == "Success") {
            this.isfetching = false;
            this.items = this.Email;
            this.totalItems = this.totalEmailRow;
          }
        })
        .catch((ex) => {
          this.isfetching = false;
          Swal.fire({
            title: "",
            text: "Error!",
            icon: "error",
            confirmButtonClass: "btn btn-danger",
          });
          
        });
    },
    onRowSelected(items) {
      this.Edit(items[0]);
    },
    async Edit(obj) {
      if (obj != undefined) {
        (this.id = obj.id),
          (this.EmailSubject = obj.emailSubject),
          (this.Name = obj.name),
         (this.EmailText = obj.emailText),
        this.isEditAble = true;
        this.$bvModal.show("createemail");
      }
    },
    remove(obj) {
      this.isRemoving = true;
      this.$store
        .dispatch("removeEmailTemplate", {
          id: obj,
        })
        .then((response) => {
          this.items = this.Email;
          this.$bvModal.hide("confirmModal");
          this.isRemoving = false;
          this.fetchData();
          if (response.message == "Success") {
            Swal.fire({
              title: "",
              text: "The Email Template has been Deleted Successfully!",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
            });
          } else {
            Swal.fire({
              title: "",
              text: response.message,
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
            });
          }
        })
        .catch((ex) => {
          this.isRemoving = false;
          Swal.fire({
            title: "",
            text: "Error!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
          });
          
        });
    },
    sendInfo(item) {
      this.emailId = item;
    },
    ok() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.isSending = true;
        if (this.id) {
          this.EmailTemplate1 = {
            id: this.id,
            Name: this.Name,
            EmailSubject: this.EmailSubject,
            EmailText: this.EmailText,
          };
          this.$store
            .dispatch("editEmail", this.EmailTemplate1)
            .then((response) => {
              this.fetchData();
              this.isSending = false;
              this.$bvModal.hide("createemail");
              Swal.fire({
                title: "",
                text: "The Email Template has been Updated Successfully!",
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
              });
            })
            .catch((ex) => {
              
              this.isSending = false;

              Swal.fire({
                title: "",
                text: "Error!",
                icon: "error",
                confirmButtonClass: "btn btn-secondary",
              });
            });
        } else {
          this.EmailTemplate1 = {
            Name: this.Name,
            EmailSubject: this.EmailSubject,
            EmailText: this.EmailText,
          };
          this.$store
            .dispatch("addEmail", this.EmailTemplate1)
            .then(() => {
              this.fetchData();
              this.isSending = false;
              this.$bvModal.hide("createemail");
              Swal.fire({
                title: "",
                text: "The Email Template has been Added Successfully!",
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
              });
            })
            .catch((ex) => {
              
              this.isSending = false;
              Swal.fire({
                title: "",
                text: "Error!",
                icon: "error",
                confirmButtonClass: "btn btn-secondary",
              });
            });
        }
        this.fetchData();
      }
    },
    Search() {
      this.fetchData();
    },
    create() {
      (this.EmailText = ""),
        (this.EmailSubject = ""),
        (this.Name = ""),
        (this.id = "");
      this.isEditAble = false;
      this.$bvModal.show("createemail");
    },
    closeModal() {
      this.$bvModal.hide("createemail");
      this.fetchData();
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
  },
};
</script>
<style lang="scss" scoped>
#size-fixed {
    width:750px;
    max-width: 750px;
    height:270px;
    overflow:scroll;
}
.titlename {
  float: left !important;
  padding-left: 19px;
}
.pageheading {
  padding-top: 20px;
  padding-bottom: 50px;
  height: 50px;
  background-color: #ffffff;
}
.form-group.invalid .invalid-feedback {
  display: block;
  text-align: initial;
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0;
  font-size: 80%;
  color: #dc3545;
}

.edit_btn {
  cursor: pointer !important;
  color: #757575 !important;
}
.edit_btn:hover {
  color: #fb8c00 !important;
}
.del_btn {
  cursor: pointer !important;
  color: #757575 !important;
}
.del_btn:hover {
  color: #bf360c !important;
}
</style>
